import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPrescriptions(ctx, data) {
      return useJwt.getPrescriptions(data).then(response => response);
    },







  },
};
